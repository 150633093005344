.TagList{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.LoadingTags{
    margin: 0px auto;
}

.DeleteIcon {
    float: right;
    cursor: pointer;
}

.DivList {
    max-height: '60vh';
    overflow: 'scroll',
}