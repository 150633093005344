.div-form {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

body {
    background-color: rgb(246, 246, 246);
}

#form {
    border: 1px rgb(193, 193, 193) solid;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#form Button {
    width: 100%;
}

.message {
    text-align: center;
    width: 100%;
}

.success {
    text-align: center;
    width: 100%;
    color: rgb(5, 169, 5);
}

.error {
    text-align: center;
    width: 100%;
    color: rgb(227, 57, 57);
}